import React, { useState } from 'react';
import styles from './ContactPage.module.css';
import { MapPin, Phone, Mail, Send } from 'lucide-react';
import Header from './header';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { ipv4 } from '../utils/config';

const ContactPage = () => {

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    message: ''
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await axios.post(ipv4+'add-lead', formData);
      
      if (response.status === 201) {
        toast.success('Message sent successfully!');
        // Reset form
        setFormData({
          first_name: '',
          last_name: '',
          email: '',
          phone_number: '',
          message: ''
        });
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error(error.response?.data?.message || 'Failed to send message. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={styles.contactContainer}>
      <Header />
      
      <div className={styles.contactHeader}>
        <h1>Get in Touch</h1>
        <p>Have a question or want to work together? We'd love to hear from you!</p>
      </div>

      <div className={styles.contactContent}>
        <div className={styles.contactInfo}>
          <h2>Contact Information</h2>
          
          <div className={styles.infoList}>
            <div className={styles.infoItem}>
              <MapPin className={styles.icon} />
              <div>
                <h3>Our Location</h3>
                <p>Allama Iqbal Road, Block 2 PECHS, 75400</p>
              </div>
            </div>

            <div className={styles.infoItem}>
              <Phone className={styles.icon} />
              <div>
                <h3>Phone</h3>
                <p>(+92)332 392433</p>
              </div>
            </div>

            <div className={styles.infoItem}>
              <Mail className={styles.icon} />
              <div>
                <h3>Email</h3>
                <p>info@biruniumlabs.com</p>
              </div>
            </div>
          </div>

          <div className={styles.mapContainer}>
            <iframe
             src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3619.798970881558!2d67.05929477406829!3d24.870714644849613!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33e8da0e95db7%3A0xeaa9243cffec7853!2sAllama%20Iqbal%20Rd%2C%20Karachi%2C%20Karachi%20City%2C%20Sindh%2C%20Pakistan!5e0!3m2!1sen!2s!4v1730666025223!5m2!1sen!2s"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
     
        <div className={styles.contactForm}>
          <form onSubmit={handleSubmit}>
            <div className={styles.formGroup}>
              <label htmlFor="first_name">First Name</label>
              <input
                type="text"
                id="first_name"
                name="first_name"
                value={formData.first_name}
                onChange={handleChange}
                required
              />
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="last_name">Last Name</label>
              <input
                type="text"
                id="last_name"
                name="last_name"
                value={formData.last_name}
                onChange={handleChange}
                required
              />
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="phone_number">Phone</label>
              <input
                type="tel"
                id="phone_number"
                name="phone_number"
                value={formData.phone_number}
                onChange={handleChange}
                required
              />
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                rows="6"
                required
              ></textarea>
            </div>

            <button 
              type="submit" 
              className={styles.submitButton}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Sending...' : 'Send Message'}
              <Send className={styles.sendIcon} />
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;