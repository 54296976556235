import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Logow from "../assets/Logow.png";
import LogoB from "../assets/LogoB.png";


import "./home.css";

const Footer = (props) => {
  // Only run on mobile devices
  if (window.innerWidth <= 768) {
    const imageContainer = document.querySelector(".home-images");
    const dots = document.querySelectorAll(".dot");
    const images = document.querySelectorAll(".home-image2");

    // Update active dot based on scroll position
    imageContainer.addEventListener("scroll", () => {
      const scrollPosition = imageContainer.scrollLeft;
      const imageWidth =
        images[0].offsetWidth +
        parseInt(getComputedStyle(images[0]).marginRight);
      const activeIndex = Math.round(scrollPosition / imageWidth);

      dots.forEach((dot, index) => {
        dot.classList.toggle("active", index === activeIndex);
      });
    });

    // Scroll to image when dot is clicked
    dots.forEach((dot, index) => {
      dot.addEventListener("click", () => {
        const imageWidth =
          images[0].offsetWidth +
          parseInt(getComputedStyle(images[0]).marginRight);
        imageContainer.scrollTo({
          left: imageWidth * index,
          behavior: "smooth",
        });
      });
    });
  }

  return (
    <footer className="home-footer">
    <div className="home-top">
      <div className="home-left4">
        <span className="home-text268">Subscribe to our newsletter</span>
        <div className="home-subscription">
          <input
            type="email"
            placeholder="Enter your email"
            className="home-textinput input"
          />
          <div className="home-subscribe">
            <span className="home-text269">Subscribe</span>
          </div>
        </div>
        <div className="home-contact">
          <span className="home-text270">Contact</span>
          <a
            href="mailto:info@biruniumlabs.com?subject=Contact-Web"
            className="home-link3"
          >
            info@biruniumlabs.com
          </a>
        </div>
      </div>
      <div className="home-right4">
        <div className="home-category5">
          <span className="home-text271">Company</span>
          <div className="home-links3">
          <Link to="/contact" className="">
            <a className="home-text272">
              Contact
            </a>
            </Link>
            <a href="/#services" className="home-text273">
              Services
            </a>
            <a href="/#portfolio" className="home-text274">
              Portfolio
            </a>
            <a href="/#testimonials" className="home-text275">
              Testimonials
            </a>
            <a href="" className="home-text276">
              Careers
            </a>
          </div>
        </div>
        <div className="home-category6"></div>
        <div className="home-category7">
          <span className="home-text277">Socials</span>
          <div className="home-links4">
            <a href="https://www.linkedin.com/company/birunium-labs/" target="_blank" className="home-text278">Linkedin</a>
            {/* <span className="home-text279">Instagram</span> */}
          </div>
        </div>
      </div>
    </div>
    <div className="home-bottom">
      <img alt="pastedImage" src={LogoB} className="home-branding" />
      <span className="home-text280">Copyright - 2024</span>
    </div>
  </footer>
  );
};

export default Footer;
