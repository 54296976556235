import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './testimonal.css'

const Testimonal = (props) => {
  return (
    <div className="testimonal-testimonal testimonal">
      <span className="testimonal-text1">{props.quote}</span>
      <div className="testimonal-author">
        <div className="testimonal-avatar"></div>
        <div className="testimonal-user">
          <span>
            {props.name1 ?? (
              <Fragment>
                <span className="testimonal-name2">
                  <span>Andy Smith</span>
                </span>
              </Fragment>
            )}
          </span>
          <span className="testimonal-divider">—</span>
          <span className="testimonal-from">{props.from}</span>
        </div>
      </div>
    </div>
  )
}

Testimonal.defaultProps = {
  name1: undefined,
  from: 'Vista Social',
  quote:
    '"Pellentesque sodales libero nulla, ac imperdiet ante tincidunt vel. Fusce auctor augue sed massa finibus, et dictum sem mollis. In luctus.”',
}

Testimonal.propTypes = {
  name1: PropTypes.element,
  from: PropTypes.string,
  quote: PropTypes.string,
}

export default Testimonal
