import React, { useState } from 'react';
import "./home.css";
const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqData = [
    {
      question: "What is your development process?",
      answer: (
        <span>
          <span>Our development process follows an agile methodology:</span>
          <br /><br />
          <span>• Initial consultation and requirements gathering</span><br />
          <span>• Project planning and scope definition</span><br />
          <span>• Design and prototyping</span><br />
          <span>• Iterative development with regular client feedback</span><br />
          <span>• Testing and quality assurance</span><br />
          <span>• Deployment and launch</span><br />
          <span>• Ongoing support and maintenance</span>
        </span>
      )
    },
    {
      question: "Do you provide cost estimates before starting a project?",
      answer: "Yes, we provide detailed cost estimates after the initial consultation and requirements gathering. Our estimates include breakdown of costs, timeline, and scope of work."
    },
    {
      question: "Do you provide enterprise-level solutions?",
      answer: (
        <span>
          <span>Yes, we offer enterprise solutions including:</span>
          <br /><br />
          <span>• Enterprise Resource Planning (ERP)</span><br />
          <span>• Customer Relationship Management (CRM)</span><br />
          <span>• Business Intelligence (BI)</span><br />
          <span>• Supply Chain Management</span><br />
          <span>• Human Resource Management Systems</span><br />
          <span>• Enterprise Content Management</span>
        </span>
      )
    },
    {
      question: "How do you handle system integration?",
      answer: (
        <span>
          <span>Our integration services include:</span>
          <br /><br />
          <span>• API development and integration</span><br />
          <span>• Legacy system modernization</span><br />
          <span>• Third-party software integration</span><br />
          <span>• Database integration</span><br />
          <span>• Cloud service integration</span><br />
          <span>• Enterprise application integration (EAI)</span>
        </span>
      )
    },
    {
      question: "How do you handle intellectual property rights?",
      answer: (
        <span>
          <span>We ensure clear IP protection through:</span>
          <br /><br />
          <span>• Comprehensive NDAs</span><br />
          <span>• IP rights transfer agreements</span><br />
          <span>• Source code ownership agreements</span><br />
          <span>• Documentation of proprietary information</span><br />
          <span>• Secure code repository management</span>
        </span>
      )
    }
  ];

  return (
    <section className="home-faq">
      <div id="faqs" className="home-heading5">
        <span className="home-text201 title">Frequently asked questions</span>
      </div>
      <div className="home-accordion">
        {faqData.map((faq, index) => (
          <div 
            key={index}
            className="accordionContainer"
            style={{
              borderBottom: '1px solid #e5e7eb',
              overflow: 'hidden'
            }}
          >
            <div 
              className="home-header2"
              onClick={() => toggleAccordion(index)}
              style={{
                cursor: 'pointer',
                padding: '1rem',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <span className="home-text202">{`— ${faq.question}`}</span>
              <svg 
                viewBox="0 0 1024 1024" 
                className="accordionIcon"
                style={{
                  width: '20px',
                  height: '20px',
                  transform: `rotate(${openIndex === index ? 180 : 0}deg)`,
                  transition: 'transform 0.3s ease'
                }}
              >
                <path d="M316 366l196 196 196-196 60 60-256 256-256-256z" />
              </svg>
            </div>
            <div 
              className="accordionContent"
              style={{
                maxHeight: openIndex === index ? '1000px' : '0',
                opacity: openIndex === index ? 1 : 0,
                transition: 'all 0.3s ease-in-out',
                overflow: 'hidden',
                padding: openIndex === index ? '1rem' : '0 1rem'
              }}
            >
              {faq.answer}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FAQ;