import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import Script from "dangerous-html/react";
import { Helmet } from "react-helmet";

import ActiveUsers from "../components/active-users";
import YouTube from "../components/you-tube";
import Testimonal from "../components/testimonal";
import Video from "../assets/Our-services-(3.0x).mp4";
import FrontVideo from "../assets/finalfront2.mp4";

import Logow from "../assets/Logow.png";
import LogoB from "../assets/LogoB.png";

import Chatzyr from "../assets/1.png";
import Sentry from "../assets/2.png";
import Goturf from "../assets/3.png";
import Easyfit from "../assets/4.png";
import AgileDev from "../assets/agile3.png";
import Agileanim from "../assets/Agile-Animation.mp4";
import "./home.css";
import Header from "./header";
import FAQ from "./faq";

const Home = (props) => {
  // Only run on mobile devices
  if (window.innerWidth <= 768) {
    const imageContainer = document.querySelector(".home-images");
    const dots = document.querySelectorAll(".dot");
    const images = document.querySelectorAll(".home-image2");

    // Update active dot based on scroll position
    imageContainer.addEventListener("scroll", () => {
      const scrollPosition = imageContainer.scrollLeft;
      const imageWidth =
        images[0].offsetWidth +
        parseInt(getComputedStyle(images[0]).marginRight);
      const activeIndex = Math.round(scrollPosition / imageWidth);

      dots.forEach((dot, index) => {
        dot.classList.toggle("active", index === activeIndex);
      });
    });

    // Scroll to image when dot is clicked
    dots.forEach((dot, index) => {
      dot.addEventListener("click", () => {
        const imageWidth =
          images[0].offsetWidth +
          parseInt(getComputedStyle(images[0]).marginRight);
        imageContainer.scrollTo({
          left: imageWidth * index,
          behavior: "smooth",
        });
      });
    });
  }

  return (
    <div className="home-container10">
      <Helmet>
        <title>Birunium Labs</title>
        <meta property="og:title" content="Birunium Labs" />
      </Helmet>
      <section className="home-hero">
        <Header></Header>
        <header className="home-content1">
          <h1 className="home-text105">
            <span className="home-text106">
              Transforming ideas into seamless software solutions
            </span>
            <br></br>
          </h1>
          <p className="home-text108">
            We turn your ideas into reality with intuitive software that
            integrates effortlessly into your business
          </p>
          <a
            href="https://www.upwork.com/agencies/1851609574277728778/"
            target="_blank"
          >
            <div className="home-get-started2">
              <span className="home-text109">Get started</span>
            </div>
          </a>
        </header>
        <div className="home-dashboard-preview">
          <div className="home-outline">
            {/* <img
              alt="pastedImage"
              src="/pastedimage-cdo-1000h.png"
              loading="lazy"
              className="home-image1"
            /> */}
            <video
              className="home-image1"
              autoPlay
              muted
              playsInline
              controls={false}
            >
              <source src={FrontVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </section>
      <section id="services-main" className="home-features">
        <div id="services" className="home-title">
          <span className="home-text110">Explore Our Premier Services</span>
          <span className="home-text111">
            <span>
              Transform challenges into
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <span className="home-text113">opportunities</span>
          </span>
        </div>
        <div className="home-cards1">
          <div className="home-container13">
            <div className="card">
              <img
                alt="pastedImage"
                src="/ux-design.png"
                className="home-icon12"
              />
              <span className="home-text114">UI/UX Design</span>
              <span className="home-text115">
                Transform your digital presence through sophisticated,
                user-centric interfaces that convert visitors into loyal
                customers. Our proven design methodology delivers intuitive
                experiences that outperform industry standards and drive
                measurable business growth.
              </span>
            </div>
            <div className="home-publish card">
              <img alt="pastedImage" src="/web.png" className="home-icon13" />
              <span className="home-text116">Web Development</span>
              <span className="home-text117">
                Experience digital excellence with custom websites engineered
                for performance and growth. From sleek mobile-first designs to
                conversion-optimized interfaces, deliver a standout web presence
                that captures your market and outperforms competitors.
              </span>
            </div>
          </div>
          <div className="home-container14">
            <div className="card home-analyze1">
              <img alt="pastedImage" src="/app.png" className="home-icon14" />
              <span className="home-text118">Mobile App Development</span>
              <span className="home-text119">
                Elevate your market reach with powerful cross-platform mobile
                applications that perform flawlessly across devices. Built with
                enterprise-grade architecture and tailored precision, your
                solution will deliver exceptional user experiences that drive
                measurable ROI.
              </span>
            </div>
            <div className="card">
              <img alt="pastedImage" src="/ai.png" className="home-icon15" />
              <span className="home-text120">AI &amp; Data Solutions</span>
              <span className="home-text121">
                Unlock transformative business insights through cutting-edge AI
                and data analytics solutions. From predictive modeling to
                intelligent automation, harness the power of your data to drive
                strategic decisions and maintain competitive advantage.
              </span>
            </div>
          </div>
          <div className="home-container15">
            <div className="card home-analyze2">
              <img
                alt="pastedImage"
                src="/software.png"
                className="home-icon16"
              />
              <span className="home-text122">
                <span className="home-text123">
                  Custom Software Development
                </span>
                <br></br>
              </span>
              <span className="home-text125">
                Streamline operations with purpose-built software solutions -
                whether it&apos;s automating HR processes, optimizing customer
                relationships, or integrating enterprise systems. Your vision,
                perfectly executed with cutting-edge technology.
              </span>
            </div>
            <div className="card">
              <img alt="pastedImage" src="/cloud.png" className="home-icon17" />
              <span className="home-text126">Cloud and Hosting Solutions</span>
              <span className="home-text127">
                Power your business with scalable cloud infrastructure
                engineered for reliability and growth. From seamless migrations
                to optimized hosting solutions, ensure your applications perform
                flawlessly while reducing operational costs.
              </span>
            </div>
          </div>
        </div>
      </section>
      <section className="home-quote-container">
        <div className="home-quote1">
          <span className="home-message">
            “Scale your content, grew lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua.”
          </span>
          <div className="home-author">
            <img
              alt="image"
              src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDV8fGdpcmx8ZW58MHx8fHwxNjY0ODA5MjE1&amp;ixlib=rb-1.2.1&amp;w=200"
              className="home-avatar"
            />
            <span className="home-quote2">
              <span className="home-text128">—  Peter McPau</span>
              <span>, Founder and CEO, Active</span>
            </span>
          </div>
        </div>
      </section>
      <section className="home-statistics">
        <div className="home-container16">
          <ActiveUsers caption="— Active users" statistic="3.5M"></ActiveUsers>
          <ActiveUsers caption="— Brands" statistic="50k"></ActiveUsers>
          <ActiveUsers
            caption="— Accounts Managed"
            statistic="250K"
          ></ActiveUsers>
          <ActiveUsers caption="— Active leads" statistic="30M"></ActiveUsers>
        </div>
      </section>
      <section className="home-banners">
        <div className="home-banner-manage">
          <div className="home-container17">
            <div className="home-left2">
              <span className="home-text130 title">
                One-Stop Destination for All Solutions
              </span>
              <span className="home-text131">
                Discover a seamless experience where you can find all your
                technology solutions in one place. From innovative software to
                reliable support, we offer everything you need to elevate your
                business. Simplify your journey and empower your growth with our
                comprehensive, all-in-one service hub.
              </span>
              <a
                href="https://www.upwork.com/agencies/1851609574277728778/"
                target="_blank"
              >
                <div className="home-get-started3 template-button">
                  <span className="home-text132">Get started</span>
                </div>
              </a>
            </div>
            <div className="home-image-container1">
              {/* <img
                alt="pastedImage"
                src="/pastedimage-wvmq%201-1200w.png"
                className="home-cards-image1"
              /> */}
              <video
                className="home-cards-image1"
                autoPlay
                muted
                playsInline
                controls={false}
              >
                <source src={Video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
        <div className="home-banner-advanced-analytics">
          <div className="home-centered-container1">
            <div className="home-image-container2">
              <img
                alt="pastedImage"
                src="/test-1200w.png"
                className="home-cards-image2"
              />
            </div>
            <div className="home-right2">
              <h2 className="home-text133 title">
                Unlock Your Business Potential
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </h2>
              <div className="home-category1">
                <span className="home-text134">
                  Collaborating with us means gaining access to tailored
                  software solutions that drive growth, streamline processes,
                  and enhance productivity. Our commitment to understanding your
                  unique needs empowers organizations and SMEs to thrive in a
                  competitive landscape
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="home-integrations">
        <div className="home-centered-container2">
          <div className="home-heading1">
            <span className="title">Empowering Global Innovation</span>
            <span className="home-text136">
              Trusted by Fortune companies and innovative startups, delivering
              enterprise solutions that drive measurable success.
            </span>
          </div>
          <div className="home-pills-container">
            <div className="home-pills">
              <div className="home-container18">
                <YouTube pastedImageSrc="/logos/3.png"></YouTube>
                <YouTube pastedImageSrc="/logos/2.png"></YouTube>
                <YouTube pastedImageSrc="/logos/4.png"></YouTube>
                <YouTube
                  // rootClassName="you-tuberoot-class-name"
                  pastedImageSrc="/logos/1.png"
                ></YouTube>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="home-how-it-works">
        <span className="home-text137 title">What Sets Us Apart</span>
        <div className="home-container19">
          <div className="home-heading2"></div>
        </div>
        <div className="home-centered-container3">
          <div className="home-category2">
            <div className="home-headng1">
              <span className="home-text138">
                Customer-Centric Approach
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span className="home-text139">
                <span>
                  We prioritize our clients’ needs by actively listening and
                  collaborating throughout the development process. This ensures
                  our solutions are tailored specifically to their business
                  goals, enhancing user satisfaction and fostering long-term
                  partnerships.
                </span>
                <br></br>
              </span>
              <a
                href="https://www.upwork.com/agencies/1851609574277728778/"
                target="_blank"
              >
                <div className="home-get-started4 template-button">
                  <span className="home-text142">Get started</span>
                </div>
              </a>
            </div>
            <div className="home-container20">
              <img
                alt="pastedImage"
                src="/pastedimage-k5xi%201-1200w.png"
                className="home-pasted-image1"
              />
            </div>
          </div>
          <div className="home-row">
            <div className="home-category3">
              <div className="home-headng2">
                <span className="home-text143">
                  Agile Development Methodology
                </span>
                <span className="home-text144">
                  Our agile approach allows for flexibility and adaptability in
                  project management. By embracing iterative development, we can
                  quickly respond to changes, incorporate feedback, and deliver
                  high-quality software solutions on time and within budget
                </span>
              </div>
              <img
                alt="pastedImage"
                src={AgileDev}
                className="home-pasted-image2"
              />
              {/* <video
              className="home-pasted-image10"
              autoPlay
              muted
              loop
              playsInline
              controls={false}
            >
                <source src={Agileanim} type="video/mp4" />
                Your browser does not support the video tag.
            </video> */}
            </div>
            <div className="home-category4">
              <div className="home-headng3">
                <span className="home-text145">Cutting-Edge Technology</span>
                <span className="home-text146">
                  We leverage the latest technologies and tools, including AI
                  and cloud computing to create innovative and scalable
                  solutions. This commitment to staying ahead of industry trends
                  enables us to provide clients with forward-thinking software
                  that drives efficiency and competitive advantage.
                </span>
              </div>
              <img
                alt="cutting edge"
                src="/cut.png"
                className="home-pasted-image3"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="home-pricing">
        <div className="home-centered-container4">
          <div className="home-heading3">
            <span className="home-text147 title">
              Pricing for all kind of businesses
            </span>
            <span className="home-text148">
              Create next-generation solutions for small business customers with
              pricing options that accommodate everyone.
            </span>
            <div className="home-selection">
              <span className="home-text149">Monthly</span>
              <span className="home-text150">Yearly</span>
            </div>
          </div>
          <div className="home-cards2">
            <div className="home-card1">
              <span className="home-text151">Free</span>
              <span className="home-text152">
                Unlimited lorem ipsum dolor sit amet, consectetur adipiscing
                elit, sed doliqua.
              </span>
              <div className="home-get-started5 template-button">
                <span className="home-text153">Start for free</span>
              </div>
              <span className="home-text154">What&apos;s included</span>
              <div className="home-bullet-points1">
                <div className="home-point10">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="home-icon18"
                  >
                    <path d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
                  </svg>
                  <span className="home-text155">
                    Sed ut pespiciatis unde omnis
                  </span>
                </div>
                <div className="home-point11">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="home-icon20"
                  >
                    <path d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
                  </svg>
                  <span className="home-text156">
                    Sed ut pespiciatis unde omnis
                  </span>
                </div>
              </div>
            </div>
            <div className="home-card2">
              <span className="home-text157">Pay as you go</span>
              <span className="home-text158">
                <span>Launch your lorem for $49/mo</span>
                <br></br>
                <span>lorem ipsum dolor sit amet, consectetur adipiscing.</span>
              </span>
              <div className="home-get-started6 template-button">
                <span className="home-text162">
                  <span>Upgrade now</span>
                  <br></br>
                </span>
              </div>
              <span className="home-text165">What&apos;s included</span>
              <div className="home-bullet-points2">
                <div className="home-point12">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="home-icon22"
                  >
                    <path d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
                  </svg>
                  <span className="home-text166">
                    Sed ut pespiciatis unde omnis
                  </span>
                </div>
                <div className="home-point13">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="home-icon24"
                  >
                    <path d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
                  </svg>
                  <span className="home-text167">
                    Sed ut pespiciatis unde omnis
                  </span>
                </div>
                <div className="home-point14">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="home-icon26"
                  >
                    <path d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
                  </svg>
                  <span className="home-text168">
                    Sed ut pespiciatis unde omnis
                  </span>
                </div>
                <div className="home-point15">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="home-icon28"
                  >
                    <path d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
                  </svg>
                  <span className="home-text169">
                    Sed ut pespiciatis unde omnis
                  </span>
                </div>
              </div>
            </div>
            <div className="home-card3">
              <span className="home-text170">Enterprise</span>
              <span className="home-text171">
                <span>
                  Custom-built lorem ipsum dolor sit amet, consectetur
                  adipiscing elit, sed doliqua.
                </span>
                <br></br>
              </span>
              <div className="home-get-started7 template-button">
                <span className="home-text174">
                  <span>Contact us</span>
                  <br></br>
                </span>
              </div>
              <span className="home-text177">What&apos;s included</span>
              <div className="home-bullet-points3">
                <div className="home-point16">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="home-icon30"
                  >
                    <path d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
                  </svg>
                  <span className="home-text178">
                    Sed ut pespiciatis unde omnis
                  </span>
                </div>
                <div className="home-point17">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="home-icon32"
                  >
                    <path d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
                  </svg>
                  <span className="home-text179">
                    Sed ut pespiciatis unde omnis
                  </span>
                </div>
                <div className="home-point18">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="home-icon34"
                  >
                    <path d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
                  </svg>
                  <span className="home-text180">
                    Sed ut pespiciatis unde omnis
                  </span>
                </div>
                <div className="home-point19">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="home-icon36"
                  >
                    <path d="M877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"></path>
                  </svg>
                  <span className="home-text181">
                    Sed ut pespiciatis unde omnis
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="testimonials" className="home-testimonals">
        <div className="home-left3">
          <span className="home-text182 title">
            Real Stories from Our Clients
          </span>
        </div>
        <div className="home-right3">
          <div className="home-column1">
            <Testimonal
              from="Chatzyr"
              quote='"Finding a development team that could turn our unique social media vision into reality was challenging until we partnered with Birunium Labs. We had this ambitious idea for a social platform that would revolutionize how people connect through shared experiences and emotions.”'
              name1={
                <Fragment>
                  <span className="home-name1">
                    <span className="home-text184">Junaid Sabir</span>
                  </span>
                </Fragment>
              }
              avatarSrc="/image552912-e3yq-200h.png"
            ></Testimonal>
            <Testimonal
              from="Sentry Group CA"
              name="Douglas J Cerson"
              quote="“They revolutionized our real estate operations with their innovative CRM and AI chatbot solution. The intelligent data management and automated customer service have transformed how we handle client relationships.”"
              name1={
                <Fragment>
                  <span className="home-name2">Douglas J Cerson</span>
                </Fragment>
              }
              avatarSrc="/image312912-mvsg-200h.png"
            ></Testimonal>
          </div>
          <div className="home-column2">
            <Testimonal
              from="Easy Fit"
              name="Zain"
              quote="“The team truly understood our requirements and delivered an efficient, user-friendly calorie tracking and fitness app right on time. Their professionalism and commitment made the process seamless, and the result exceeded our expectations. Highly recommended!”"
              name1={
                <Fragment>
                  <span className="home-name3">Zain</span>
                </Fragment>
              }
              avatarSrc="/image502912-6wy-200h.png"
            ></Testimonal>
            <Testimonal
              from="E-QUEUE"
              name="Yousuf Al Dahi"
              quote="“The team’s expertise in developing our queuing system for banks, ministries, and hospitals in Saudi Arabia was outstanding. They understood our requirements perfectly and delivered an efficient, scalable solution on schedule. Their commitment to quality and attention to detail made the entire process seamless. Highly recommended for any organization looking to optimize customer flow!”"
              name1={
                <Fragment>
                  <span className="home-name4">Yousuf Al Dahi</span>
                </Fragment>
              }
              avatarSrc="/image202912-zekh-200h.png"
            ></Testimonal>
          </div>
        </div>
        <span className="home-text185">
          <span>Show more</span>
          <br></br>
        </span>
      </section>
      <section class="home-action-bar">
        <div class="home-action" id="portfolio">
          <div class="home-heading4">
            <h2 class="home-text188 title">Our Work Speaks for Itself</h2>
          </div>
          <div class="home-images">
            <img alt="Chatzyr Project" src={Chatzyr} class="home-image2" />
            <img alt="Sentry Project" src={Sentry} class="home-image2" />
            <img alt="Goturf Project" src={Goturf} class="home-image2" />
            <img alt="Easyfit Project" src={Easyfit} class="home-image2" />
          </div>
          {/* <!-- Scroll indicator dots for mobile --> */}
          <div class="scroll-dots">
            <span class="dot active"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
          </div>
          <a
                href="https://www.upwork.com/agencies/1851609574277728778/"
                target="_blank"
              >
          <button class="home-get-started8">
            <span class="home-text198">Get Started</span>
          </button>
          </a>
        </div>
      </section>
      <FAQ></FAQ>
    </div>
  );
};

export default Home;
