import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import Logow from "../assets/Logow.png";
import LogoB from "../assets/LogoB.png";
import "./home.css";

const Header = (props) => {
  // Only run on mobile devices
  // if (window.innerWidth <= 768) {
  //   const imageContainer = document.querySelector(".home-images");
  //   const dots = document.querySelectorAll(".dot");
  //   const images = document.querySelectorAll(".home-image2");

  //   // Update active dot based on scroll position
  //   imageContainer.addEventListener("scroll", () => {
  //     const scrollPosition = imageContainer.scrollLeft;
  //     const imageWidth =
  //       images[0].offsetWidth +
  //       parseInt(getComputedStyle(images[0]).marginRight);
  //     const activeIndex = Math.round(scrollPosition / imageWidth);

  //     dots.forEach((dot, index) => {
  //       dot.classList.toggle("active", index === activeIndex);
  //     });
  //   });

  //   // Scroll to image when dot is clicked
  //   dots.forEach((dot, index) => {
  //     dot.addEventListener("click", () => {
  //       const imageWidth =
  //         images[0].offsetWidth +
  //         parseInt(getComputedStyle(images[0]).marginRight);
  //       imageContainer.scrollTo({
  //         left: imageWidth * index,
  //         behavior: "smooth",
  //       });
  //     });
  //   });
  // }
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  return (
    <header className="home-header1">
      <div
        id="mobile-menu"
        className="home-mobile-navigation"
        style={{
          transform: isMobileMenuOpen ? "translateX(0%)" : "translateX(100%)",
          transition: "transform 0.3s ease-in-out",
        }}
      >
        <Link to="/" className="home-navlink Link">
          <img
            alt="pastedImage"
            src={Logow}
            className="home-logo2"
            onClick={() => setIsMobileMenuOpen(false)}
          />
        </Link>
        <div className="home-links1" onClick={() => setIsMobileMenuOpen(false)}>
          <a href="/#services" className="home-link1 Link">
            Services
          </a>
          <a href="/#portfolio" className="home-link1 Link">
            Portfolio
          </a>
          <a href="/#faqs" className="home-link2 Link">
            FAQs
          </a>
          <Link to="/contact" className="">
            <div className="home-get-started20">
              <span className="home-text104">Contact Us</span>
            </div>
          </Link>
        </div>
        <div
          id="close-mobile-menu"
          className="home-close-mobile-menu"
          onClick={() => setIsMobileMenuOpen(false)}
        >
          <svg viewBox="0 0 804.5714285714286 1024" className="home-icon10">
            <path d="M741.714 755.429c0 14.286-5.714 28.571-16 38.857l-77.714 77.714c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-168-168-168 168c-10.286 10.286-24.571 16-38.857 16s-28.571-5.714-38.857-16l-77.714-77.714c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l168-168-168-168c-10.286-10.286-16-24.571-16-38.857s5.714-28.571 16-38.857l77.714-77.714c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l168 168 168-168c10.286-10.286 24.571-16 38.857-16s28.571 5.714 38.857 16l77.714 77.714c10.286 10.286 16 24.571 16 38.857s-5.714 28.571-16 38.857l-168 168 168 168c10.286 10.286 16 24.571 16 38.857z"></path>
          </svg>
        </div>
      </div>
      <div className="home-desktop-navigation">
        <nav className="home-centered">
          <div className="home-left1">
            <Link to="/" className="home-navlink Link">
              <img alt="pastedImage" src={Logow} className="home-logo2" />
            </Link>
            <div className="home-links2">
              <a href="/#services" className="home-link1 Link">
                Services
              </a>
              <a href="/#portfolio" className="home-link1 Link">
                Portfolio
              </a>
              <a href="/#faqs" className="home-link2 Link">
                FAQs
              </a>
            </div>
          </div>
          <div className="home-right1">
            <Link to="/contact" className="">
              <div className="home-get-started1">
                <span className="home-text104">Contact Us</span>
              </div>
            </Link>
            <div
              id="open-mobile-menu"
              className="home-burger-menu"
              onClick={() => setIsMobileMenuOpen(true)}
            >
              <img
                alt="pastedImage"
                src="/pastedimage-yxbd.svg"
                className="home-mobile-menu-button"
              />
            </div>
          </div>
        </nav>
      </div>
      <div></div>
    </header>
  );
};

export default Header;


