import React,{useEffect} from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useLocation
} from 'react-router-dom'
import { Toaster } from 'react-hot-toast';
import './style.css'
import Home from './views/home'
import NotFound from './views/not-found'
import ContactPage from './views/contactus'
import Header from './views/header'
import Footer from './views/footer'

const App = () => {
  function ScrollToTop() {
    const { pathname } = useLocation();
  
    useEffect(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [pathname]);
  
    return null;
  }
  return (
    <Router>
      <ScrollToTop></ScrollToTop>
      {/* <Header></Header> */}
      <Switch>
        <Route component={Home} exact path="/" />
        <Route component={ContactPage} exact path="/contact" />
        <Route component={NotFound} path="**" />
        <Redirect to="**" />
      </Switch>
      <Toaster position="top-right" />
      <Footer></Footer>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
